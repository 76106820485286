const templateVariantSettings = {
  header: "COMPRESSED",
  homePage: "HomeTemplateV9",
  websiteName: "INSTEP Credit Union",
  about: null,
  contact: "ContactUsTemplateV6",
  footer: null,
  speedBump: {
    borderWithRadius: false,
  },
};

module.exports = templateVariantSettings;
